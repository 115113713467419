<template>
  <main class="bg-light">
    <div>
      <keep-alive>
        <top-nav/>
      </keep-alive>
    </div>
    <div>
      <router-view
        v-on:load="load"
      />
    </div>
    <div v-if="pending">
      <b-spinner
        size="lg"
        variant="danger"
      />
    </div>
    <b-container v-else class="mt-4">
      <b-row>
        <b-col>
          <b-table-simple small responsive="" bordered class="bg-white">
            <b-thead>
              <b-tr>
                <b-th>
                  SEQ
                </b-th>
                <b-th>
                  TITLE
                </b-th>
                <b-th>
                  등록자
                </b-th>
                <b-th>
                  DATE
                </b-th>
                <b-th>
                  상태
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="q in question"
                :key="q._id"
                :class="selectedId === q._id ? 'active' : ''"
              >
                <b-td class="text-left">
                  <router-link :to="`/answer/${q._id}`">
                    {{ q._id }}
                  </router-link>
                </b-td>
                <b-td class="text-left">
                  <div class="d-block" style="max-width: 200px;">
                    <p
                        class="text-truncate my-1"
                        style="font-size: 0.8rem"
                    >
                      {{ q.content }}
                    </p>
                  </div>
                </b-td>
                <b-td>
                  <div>
                    {{ q.regUserName }}
                  </div>
                </b-td>
                <b-td>
                  <div>
                    {{ new Date(q.regDate).toLocaleString() }}
                  </div>
                </b-td>
                <b-td>
                  <div>
                    {{ q.answerDate ? new Date(q.answerDate).toLocaleString() : '답변 전' }}
                  </div>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div>
            <b-pagination-nav
                v-if="question.length"
                :link-gen="linkGen"
                v-model="page"
                :number-of-pages="numberOfPages"
                use-router
                aria-controls="my-table"
                first-number
                last-number
                first-text="«"
                prev-text="‹"
                next-text="›"
                last-text="»"
                align="right"
                size="sm"
            />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </main>
</template>

<script>
export default {
  name: "answer",
  data: () => ({
    pending: false,
    question: [],
    page: 1,
    total: 0,
    perPage: 0,
    error: null,
    numberOfPages: 0,
    filterData: {
    },
    openIds: null,
    selectedId: null
  }),
  created () {
    (async () => {
      const {_id} = this.$route.params
      if (_id) this.selectedId = _id
      await this.load()
    })()
  },
  methods: {
    async load () {
      this.pending = true
      try {
        const {data} = await this.axios({
          url: `/support/question`,
          params: {
            page: this.page,
            perPage: 20
          }
        })
        const {result, page, total, perPage, question, numberOfPages} = data
        if (result) {
          this.page = page
          this.total = total
          this.perPage = perPage
          this.question = question
          this.numberOfPages = numberOfPages
        }
      } catch (e) {
      }
      this.pending = false
    },
    linkGen (page) {
      return `?page=${page}`
      // return `?page=${page}` + '&' + Object.entries(this.filterData).map(v => v[1] ? `${v[0]}=${v[1]}` : `${v[0]}`).join('&')
    },
  }
}
</script>

<style scoped>
  main {
    min-height: 100vh;
  }
  th, td {
    vertical-align: middle;
    font-size: 0.8rem;
  }
  tr.active {
    color: red;
    font-weight: bold;
    background-color: #F5F5F5;
  }
</style>
